<template>
  <div class="WalletMethod_Confirm">
    <v-form
      ref="form"
      autocomplete="false"
    >
      <WalletMethodTitle>
        <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Confirm invoice</div>
      </WalletMethodTitle>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="md:grid md:grid-cols-[45%,_auto]">
        <div>
          <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
            <img src="@/assets/icons/icon-card-details-confirm-deposit.svg" class="mr-[5px]">
            <div>Card details</div>
          </div>
          <div>
            <div
              v-if="invoiceGatewayPay.number"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Card number:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal text-right">{{ invoiceGatewayPay.number }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.expire_card_date"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">MM / YY:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.expire_card_date }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.holder_name"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Name on card:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.holder_name }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.amount"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Amount:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.amount }} {{invoiceGatewayPay.currency}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
            <img src="@/assets/icons/icon-client-address-confirm-deposit.svg" class="mr-[5px]">
            <div>Client address</div>
          </div>
          <div>
            <div
              v-if="invoiceGatewayPay.country"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Country:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ getCountryName }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.city"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">City:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.city }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.postal_code"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Postal code:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.postal_code }}</div>
            </div>
            <div
              v-if="invoiceGatewayPay.address_line1"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Address:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ invoiceGatewayPay.address_line1 }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="flex items-center flex-wrap justify-center mt-[15px]">
        <Button
          class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
          text="Previous step"
          size="large"
          @click="handleCancel"
        />
        <OutlineButton
          class="2xl:max-w-[260px]"
          text="Confirm"
          fullWidth
          :disabled="invoiceGatewayPay.isLoading"
          @click="handleSubmitInvoice"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import WalletMethodTitle from "@/components/WalletMethodTitle";
import Button from "@/components/_common/FormElements/Button/Button";
import OutlineButton from "@/components/_common/FormElements/OutlineButton/OutlineButton";
import { validateForm } from '@/mixins/validateForm';
import Countries from '@/utils/countries.js';
// import { helpersGetNotificationPageUrl } from '@/utils/helpers';
import { showAppNotification } from '@/utils/appNotification';

export default {
  name: "InvoiceGatewayStepConfirm",
  props: {
    currency: {
      type: String,
      required: false
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [ validateForm ],
  components: {
    WalletMethodTitle,
    Button,
    OutlineButton
  },
  computed: {
    ...mapState('invoicing', [ 'invoiceGatewayPay' ]),
    getCountryName() {
      return Countries.find(country => country.code === this.invoiceGatewayPay.country).country
    }
  },
  methods: {
    // ...mapActions('depositAndWithdrawal', [ 'apiGatewayAddressRequest' ]),
    ...mapActions('invoicing', [ 'updateInvoiceGatewayForm', 'apiPayPublicInvoice'  ]),
    handleSubmitInvoice() {
      this.validateForm().then(() => {
        this.getInvoiceGatewayAddress();
      });
    },
    handleCancel() {
      this.updateInvoiceGatewayForm({ value: 2, field: 'step' })
    },
    getInvoiceGatewayAddress() {
      const request = {
        comment: this.invoiceGatewayPay.comment,
        currency: this.invoiceGatewayPay.currency,
        amount: this.invoiceGatewayPay.amount,
        payment_method: "CARD",
        order_id: this.invoiceGatewayPay.order_id,
        additional_info: {
          // urls: {
          //   success_url: helpersGetNotificationPageUrl("depositPbt"),
          //   fail_url: helpersGetNotificationPageUrl("depositPbt"),
          //   post_redirect: helpersGetNotificationPageUrl("depositPbt"),
          // },
          card: {
            number: this.invoiceGatewayPay.number.replaceAll( /\s/g, "" ),
            expire_month: this.invoiceGatewayPay.expire_card_date.split('/')[0],
            expire_year: this.invoiceGatewayPay.expire_card_date.split('/')[1],
            cv2: this.invoiceGatewayPay.cv2,
            holder_name: this.invoiceGatewayPay.holder_name,
            billing_details: {
              country: this.invoiceGatewayPay.country,
              city: this.invoiceGatewayPay.city,
              address_line1: this.invoiceGatewayPay.address_line1,
              postal_code: this.invoiceGatewayPay.postal_code,
            }
          }
        }
      }
      this.invoiceGatewayPay.isLoading = true
      // this.apiGatewayAddressRequest(request).then(response => {
      this.apiPayPublicInvoice(request).then(response => {
        const { data: { status, url } } = response
        if ( url ) { 
          document.location.assign( url ) 
        } else {
          showAppNotification('error', "An error occurred, please contact support");

          if (status !== "FAILED") {
          // if ( true ) {
            this.comment = ""
            this.number = ""
            this.expire_month = ""
            this.expire_year = ""
            this.cv2 = ""
            this.first_name = ""
            this.last_name = ""
            this.country = ""
            this.city = ""
            this.address_line1 = ""
            this.postal_code = ""
          }

          // if (status !== "PENDING") this.invoiceGatewayPay.isLoading = false
        }
      });
    },
  },
};
</script>