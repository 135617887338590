<template>
  <div class="wrapper-invoice-page">
    <div class="invoice_root">
      <div class="invoice_wrapper">
        <div class="invoice_container">
          <div
            class="invoice_verification"
            v-if="!isMethodAvailable"
          >
            <PassVerification operation="deposit"/>
          </div>
          <div
            class="invoice_content"
            v-if="isMethodAvailable"
          >
            <StepsBar
              :stepsBar="stepsBar"
              :activeStep="invoiceGatewayPay.step"
            ></StepsBar>
            <component
              :is="invoiceCurrentStepComponent"

            ></component>
          </div>
          <!--        <div class="DepositCrypto_faq">-->
          <!--          <LimitFeePanel-->
          <!--            :method="methodName"-->
          <!--            operation="deposit"-->
          <!--            :limits="prepareLimitsInfo"-->
          <!--            :fees="prepareFeeInfo"-->
          <!--            :currency="currency"-->
          <!--          />-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
// import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel';
import PassVerification from '@/components/_common/PassVerification/PassVerification';
import InvoiceGatewayStepOne from "./InvoiceGatewayStepOne";
import InvoiceGatewayStepTwo from "./InvoiceGatewayStepTwo";
import InvoiceGatewayStepConfirm from "./InvoiceGatewayStepConfirm";
import StepsBar from "@/components/_common/StepsBar";

export default {
  components: {
    PassVerification,
    // LimitFeePanel,
    StepsBar,
    InvoiceGatewayStepOne,
    InvoiceGatewayStepTwo,
    InvoiceGatewayStepConfirm
  },
  data() {
    return {
      // currency: 'EUR',
      methodName: 'GATEWAY',
      stepsBar: [
        {
          stepNumber: 1,
          stepName: 'Card details',
          iconUrl: 'icon-card-details-step.svg'
        },
        {
          stepNumber: 2,
          stepName: 'Client address',
          iconUrl: 'icon-client-address-step.svg'
        },
        {
          stepNumber: 3,
          stepName: 'Confirm',
          iconUrl: 'icon-confirm-step.svg'
        }
      ]
    }
  },
  props: {
    // currency: {
    //   type: String,
    //   required: true,
    //   default: 'UAH',
    // },
    // methodName: {
    //   type: String,
    //   default: '',
    // },
  },
  computed: {
    ...mapState('invoicing', [ 'invoiceGatewayPay' ]),
    ...mapGetters('user', [ 'isVerified' ]),
    invoiceCurrentStepComponent() {
      const step = this.invoiceGatewayPay.step
      switch (step) {
        case 1:
          return 'InvoiceGatewayStepOne';
        case 2:
          return 'InvoiceGatewayStepTwo';
        case 3:
          return 'InvoiceGatewayStepConfirm';
        default:
          return 1;
      }
    },
    // prepareLimitsInfo() {
    //   const limitsObject = getLimitsByCurrency({
    //     currency: this.currency,
    //     type: 'GATEWAY',
    //     operation: 'deposit',
    //   });
    //   return limitsObject;
    // },
    // prepareFeeInfo() {
    //   const feeObject = calculateOperationFee({
    //     currency: this.currency,
    //     type: 'GATEWAY',
    //     operation: 'deposit',
    //   });
    //   return feeObject;
    // },
    isMethodAvailable() {
      // if (this.methodName) {
      //   const currentMethod = allActiveDepositMethods(this.currency)[ this.methodName ];
      //   const { disable_description } = currentMethod.CARD || currentMethod;
      //   if (disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified) {
      //     return false;
      //   }
      //   return true;
      // }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'InvoiceGateway';
</style>
